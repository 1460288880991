<template>
  <div id="correctMeasures">
    <el-dialog
      :title="correctMeasuresFormTitle"
      width="1200px"
      :visible.sync="correctMeasuresDialogVisible"
      :close-on-click-modal="false"
      @close="correctMeasuresDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="correctMeasuresFormRef"
        :model="correctMeasuresForm"
        :rules="correctMeasuresFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="纠正类型" prop="correctionType">
              <el-select
                v-model="correctMeasuresForm.correctionType"
                placeholder="请选择纠正类型"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="item in ['内部审核', '第三方审核', '客户投诉']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="质量信息反馈表编号" prop="qualityInfoNum">
              <el-input v-model="correctMeasuresForm.qualityInfoNum" placeholder="请输入质量信息反馈表编号" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="不合格状况说明" prop="unqualifiedExplain">
              <el-input
                v-model="correctMeasuresForm.unqualifiedExplain"
                placeholder="请输入不合格状况说明"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="填表人" prop="completedBy">
              <el-input v-model="correctMeasuresForm.completedBy" placeholder="请输入填表人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="completedByDate">
              <el-date-picker v-model="correctMeasuresForm.completedByDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="原因分析" prop="causeAnalysis">
              <el-input
                v-model="correctMeasuresForm.causeAnalysis"
                placeholder="请输入原因分析"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="责任人" prop="responsible">
              <el-input v-model="correctMeasuresForm.responsible" placeholder="请输入责任人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="responsibleDate">
              <el-date-picker v-model="correctMeasuresForm.responsibleDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar v-if="correctMeasuresFormTitle !== '纠正和预防措施实施表详情'">
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="correctMeasuresDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="correctMeasures"
              title="纠正预防措施"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="implementationDate"
              title="实施日期"
              :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
            />
            <vxe-table-column
              field="completionDate"
              title="完成日期"
              :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
            />
            <vxe-table-column
              field="responsible"
              title="负责者"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column v-if="correctMeasuresFormTitle !== '纠正和预防措施实施表详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="纠正或预防措施效果确认与评估" prop="confirmEvaluate" label-width="230px">
              <el-select
                v-model="correctMeasuresForm.confirmEvaluate"
                placeholder="请选择纠正或预防措施效果确认与评估"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="item in ['纠正措施有效，不合格状况已消除', '纠正或预防措施无效，不合格状况仍存在']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="提供的证实资料" prop="provideInfo">
              <el-input
                v-model="correctMeasuresForm.provideInfo"
                placeholder="请输入提供的证实资料"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="确认人" prop="confirmer">
              <el-input v-model="correctMeasuresForm.confirmer" placeholder="请输入确认人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="confirmerDate">
              <el-date-picker v-model="correctMeasuresForm.confirmerDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="correctMeasuresDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="correctMeasuresFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="correctMeasuresFormTitle === '纠正和预防措施实施表详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="质量信息反馈表编号">
        <el-input v-model="searchForm.qualityInfoNum" placeholder="请输入质量信息反馈表编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="correctMeasuresPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="correctionType" label="纠正类型" />
      <el-table-column prop="qualityInfoNum" label="质量信息反馈表编号" />
      <el-table-column prop="unqualifiedExplain" label="不合格状况说明" />
      <el-table-column prop="completedBy" label="填表人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.completedByDate">{{ scope.row.completedByDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="causeAnalysis" label="原因分析" />
      <el-table-column prop="responsible" label="责任人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.responsibleDate">{{ scope.row.responsibleDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="confirmEvaluate" label="纠正或预防措施效果确认与评估" />
      <el-table-column prop="provideInfo" label="提供的证实资料" />
      <el-table-column prop="confirmer" label="确认人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmerDate">{{ scope.row.confirmerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="correctMeasuresPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addCorrectMeasures,
  deleteCorrectMeasures,
  updateCorrectMeasures,
  selectCorrectMeasuresInfo,
  selectCorrectMeasuresList
} from '@/api/quality/correctMeasures'
import moment from 'moment'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      correctMeasuresDialogVisible: false,
      correctMeasuresFormTitle: '',
      correctMeasuresForm: {
        id: '',
        correctionType: '',
        qualityInfoNum: '',
        unqualifiedExplain: '',
        completedBy: '',
        completedByDate: '',
        causeAnalysis: '',
        responsible: '',
        responsibleDate: '',
        confirmEvaluate: '',
        provideInfo: '',
        confirmer: '',
        confirmerDate: '',
        correctMeasuresDetailJson: ''
      },
      correctMeasuresFormRules: {
        qualityInfoNum: [{ required: true, message: '质量信息反馈表编号不能为空', trigger: ['blur', 'change']}]
      },
      correctMeasuresPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        qualityInfoNum: ''
      },
      correctMeasuresDetailList: []
    }
  },
  created () {
    selectCorrectMeasuresList(this.searchForm).then(res => {
      this.correctMeasuresPage = res
    })
  },
  methods: {
    correctMeasuresDialogClose () {
      this.$refs.correctMeasuresFormRef.resetFields()
      this.correctMeasuresDetailList = []
    },
    correctMeasuresFormSubmit () {
      if (this.correctMeasuresFormTitle === '纠正和预防措施实施表详情') {
        this.correctMeasuresDialogVisible = false
        return
      }
      this.$refs.correctMeasuresFormRef.validate(async valid => {
        if (valid) {
          this.correctMeasuresForm.correctMeasuresDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.correctMeasuresFormTitle === '新增纠正和预防措施实施表') {
            await addCorrectMeasures(this.correctMeasuresForm)
          } else if (this.correctMeasuresFormTitle === '修改纠正和预防措施实施表') {
            await updateCorrectMeasures(this.correctMeasuresForm)
          }
          this.correctMeasuresPage = await selectCorrectMeasuresList(this.searchForm)
          this.correctMeasuresDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.correctMeasuresFormTitle = '新增纠正和预防措施实施表'
      this.correctMeasuresDialogVisible = true
      this.correctMeasuresForm.qualityInfoNum = 'JY' + moment().format('YYYY') + '-'
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteCorrectMeasures(row.id)
        if (this.correctMeasuresPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.correctMeasuresPage = await selectCorrectMeasuresList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.correctMeasuresFormTitle = '修改纠正和预防措施实施表'
      this.correctMeasuresDialogVisible = true
      this.selectCorrectMeasuresInfoById(row.id)
    },
    handleInfo (index, row) {
      this.correctMeasuresFormTitle = '纠正和预防措施实施表详情'
      this.correctMeasuresDialogVisible = true
      this.selectCorrectMeasuresInfoById(row.id)
    },
    selectCorrectMeasuresInfoById (id) {
      selectCorrectMeasuresInfo(id).then(res => {
        this.correctMeasuresForm.id = res.id
        this.correctMeasuresForm.correctionType = res.correctionType
        this.correctMeasuresForm.qualityInfoNum = res.qualityInfoNum
        this.correctMeasuresForm.unqualifiedExplain = res.unqualifiedExplain
        this.correctMeasuresForm.completedBy = res.completedBy
        this.correctMeasuresForm.completedByDate = res.completedByDate
        this.correctMeasuresForm.causeAnalysis = res.causeAnalysis
        this.correctMeasuresForm.responsible = res.responsible
        this.correctMeasuresForm.responsibleDate = res.responsibleDate
        this.correctMeasuresForm.confirmEvaluate = res.confirmEvaluate
        this.correctMeasuresForm.provideInfo = res.provideInfo
        this.correctMeasuresForm.confirmer = res.confirmer
        this.correctMeasuresForm.confirmerDate = res.confirmerDate
        this.correctMeasuresDetailList = res.correctMeasuresDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCorrectMeasuresList(this.searchForm).then(res => {
        this.correctMeasuresPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCorrectMeasuresList(this.searchForm).then(res => {
        this.correctMeasuresPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCorrectMeasuresList(this.searchForm).then(res => {
        this.correctMeasuresPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `纠正和预防措施实施表${this.correctMeasuresForm.completedByDate.substring(0, 10)}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
